/* src/styles/IframeReader.css */

.iframe-reader {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .iframe-reader iframe {
    width: 90%;
    height: calc(100% - 60px); /* Adjust the header height here */
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 10px; /* Adjust spacing from the header */
  }
  
  /* Header.css */
  
  .header {
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo-heading {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 30px;
    margin-right: 10px;
  }
  
  .header-buttons {
    display: flex;
    margin-top: 20px;
  }
  
  .header-buttons button {
    margin: 0 5px;
  }
  
  .home-button {
    position: fixed;
    left: 10px;
    top: 30px; /* Adjust the top position as needed */
    cursor: pointer;
    z-index: 999; /* Ensure it's above other content */
}

.home-button img {
    height: 25px; /* Decrease the height of the image */
    width: auto; /* Let the width adjust proportionally */
  }
  
  