/* src/styles/LandingPage.css */
.landing-page {
  padding: 20px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-buttons {
  margin-top: 10px;
  border-radius: 8px;
}

.header-buttons button {
  margin: 5px;
  border-radius: 8px;
}

.search-form {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  width: 280px;
  margin-right: 10px;
  border-radius: 10px;
}

.search-button {
  padding: 10px 20px;
  display: inline-block;
  margin: 5px;
  background-color: #007bff; /* Blue background color */
  color: #ffffff; /* White text color */
  border: none;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
  font-size: 16px;
}
.search-button:hover{
  background-color: #0056b3; /* Darker blue on hover */
}

.book-section {
  margin-top: 20px;
}
 
 .book-section h2 {
  text-align: center;
} 

.book-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.book-item {
  margin: 10px;
  text-align: center;
}

.book-item img {
  width: 150px;
  height: 200px;
  object-fit: cover;
}

.book-item p {
  margin: 10px 0; 
}

.book-item button{
  border-radius: 8px;
  background-color: #88ff00;
}

.book-item button:hover{
  background-color: #00ffd9; 
}
