.header {
    padding: 20px;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .logo-heading {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 150px; /* Adjust the size as needed */
    height: 100px;
    margin-right: 15px; /* Space between the logo and the heading */
  }